<template>
    <!-- eslint-disable -->
    <Form :action="action" :dataProvinsiVal="dataProvinsi" :gelarKekeluargaanVal="null" :provinsiVal="{text: '-- Pilih Opsi --', value: null}" :kotaKabupatenVal="{text: '-- Pilih Opsi --', value: null}" :kecamatanVal="{text: '-- Pilih Opsi --', value: null}" :kelurahanVal="{text: '-- Pilih Opsi --', value: null}" :agamaVal="{text: '-- Pilih Opsi --', value: null}" :tanggalLahirVal="''" :dataPembiayaanVal="dataPembiayaan" :dataDokterPengirimVal="dataDokterPengirim" :dataJaringanDidapatVal="dataJaringanDidapat" :dataLokasiAsalJaringanVal="dataLokasiAsalJaringan" :dataRsKlinikVal="dataRsKlinik" :dataSediaanVal="[{value: null, text: '-- Pilih Opsi --'}]" :dataDokterPemeriksaVal="dataDokterPemeriksa" :rsKlinikVal="{text: '-- Pilih Opsi --', value: null}" :dokterPemeriksaVal="null" :pembiayaanVal="{text: '-- Pilih Opsi --', value: null}" :jenisPemeriksaanVal="null" :dokterPengirimVal="{text: '-- Pilih Opsi --', value: null}" :isSpesimenDiterimaVal="[]" :jaringanVal="null" :sediaanVal="null" :statusVal="'Draf'" :tanggalDiterimaVal="tanggalDiterima" :dataFiksasiVal="dataFiksasi" :fiksasiVal="{text: '-- Pilih Opsi --', value: null}" :kewarganegaraanVal="'WNI'" :dataJenisPemeriksaanVal="dataJenisPemeriksaan" :dataKotaKabupatenVal="[]" :dataKecamatanVal="[]" :dataKelurahanVal="[]" :dataIcd10Val="dataIcd10" :keteranganKlinisVal="'-'" :diagnosaKlinisVal="'-'" :riwayatLabPaVal="'-'"
     />
</template>

<script>
import Form from './Form.vue'
import {ref } from '@vue/composition-api'
var moment = require('moment');

export default {
    setup(props, context){
        const g = context.root
        const $http = g.$http

        var dataProvinsi = ref([])

        var dataPembiayaan = ref([])
        var dataDokterPengirim = ref([])
        var dataDokterPemeriksa = ref([])
        var dataJaringanDidapat = ref([])
        var dataJenisPemeriksaan = ref([])
        var dataLokasiAsalJaringan = ref([])
        var dataRsKlinik = ref([])
        var dataFiksasi = ref([])
        var dataIcd10 = ref([])
        
        var tanggalDiterima = ref(moment().format('DD-MM-YYYY hh:mm'))

        const getData = () => {
            $http({
                url: '/pemeriksaan/pemeriksaan/tambah',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataProvinsiVal, dataPembiayaanVal, dataDokterPengirimVal, dataJaringanDidapatVal, dataLokasiAsalJaringanVal, dataRsKlinikVal, dataDokterPemeriksaVal, dataFiksasiVal, dataJenisPemeriksaanVal, dataIcd10Val} = res.data.data.dataPemeriksaan
                    dataProvinsi.value = dataProvinsiVal
                    dataPembiayaan.value = dataPembiayaanVal
                    dataDokterPengirim.value = dataDokterPengirimVal
                    dataDokterPemeriksa.value = dataDokterPemeriksaVal
                    dataJaringanDidapat.value = dataJaringanDidapatVal
                    dataJenisPemeriksaan.value = dataJenisPemeriksaanVal
                    dataLokasiAsalJaringan.value = dataLokasiAsalJaringanVal
                    dataRsKlinik.value = dataRsKlinikVal
                    dataFiksasi.value = dataFiksasiVal
                    dataIcd10.value = dataIcd10Val
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Get Data`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()
        return {
            dataProvinsi, dataPembiayaan, dataDokterPengirim, dataJaringanDidapat, dataLokasiAsalJaringan, dataRsKlinik, dataDokterPemeriksa, tanggalDiterima, dataFiksasi, dataJenisPemeriksaan, dataIcd10
        }
    },
    data(){
        return{
            action: 'Tambah'
        }
    },
    components: {
        Form
    }
}
</script>